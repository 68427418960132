import React, { useState, useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import { motion, useAnimation } from "framer-motion"

import {
    SinglePageQuery,
} from "./__generated__/SinglePageQuery"

export default ({ location, data }: PageProps<SinglePageQuery, {}>) => {
    const item = data.microcmsPage;

    const Fade = ({ children }) => {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1
                }}>
                {children}
            </motion.div>
        )
    }

    return (
        <Layout
            seo={{
                title: item.title,
                description: item.body,
                // image: item.image.url
            }}
            location={location}
        >
            <Fade>
                <div className="container mx-auto p-6 py-12">
                    <div className="title ">
                        <h2 className="font-black text-5xl text-color-1 text-center">
                            {item.title}
                        </h2>
                    </div>
                    <div className="post-content" dangerouslySetInnerHTML={{ __html: item.body }} >
                    </div>
                </div>
            </Fade>
        </Layout>
    );
}

export const query = graphql`
query SinglePageQuery($id: String) {
  microcmsPage(identity: {eq: $id}) {
    identity
    title
    body
  }
}
`
